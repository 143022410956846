import {
    faAddressBook as faAddressBookRegular,
    faAlarmClock as faAlarmClockRegular,
    faAlarmExclamation as faAlarmExclamationRegular,
    faAnalytics as faAnalyticsRegular,
    faAnchor as faAnchorRegular,
    faAngleDown as faAngleDownRegular,
    faAngleLeft as faAngleLeftRegular,
    faAngleRight as faAngleRightRegular,
    faAngleUp as faAngleUpRegular,
    faArchive as faArchiveRegular,
    faArrowAltDown as faArrowAltDownRegular,
    faArrowAltLeft as faArrowAltLeftRegular,
    faArrowAltUp as faArrowAltUpRegular,
    faArrowCircleRight as faArrowCircleRightRegular,
    faArrowFromBottom as faArrowFromBottomRegular,
    faArrowFromLeft as faArrowFromLeftRegular,
    faArrowFromRight as faArrowFromRightRegular,
    faArrowLeft as faArrowLeftRegular,
    faArrowRight as faArrowRightRegular,
    faArrowToBottom as faArrowToBottomRegular,
    faArrowToLeft,
    faAsterisk as faAsteriskRegular,
    faAward as faAwardRegular,
    faBan as faBanRegular,
    faBarcodeAlt as faBarcodeAltRegular,
    faBars as faBarsRegular,
    faBell as faBellRegular,
    faBook as faBookRegular,
    faBox as faBoxRegular,
    faBoxAlt as faBoxAltRegular,
    faBoxCheck as faBoxCheckRegular,
    faBoxes as faBoxesRegular,
    faBriefcase as faBriefcaseRegular,
    faBroom as faBroomRegular,
    faBug as faBugRegular,
    faBuilding as faBuildingRegular,
    faCalendar as faCalendarRegular,
    faCalendarAlt as faCalendarAltRegular,
    faCalendarEdit as faCalendarEditRegular,
    faCamera as faCameraRegular,
    faCartPlus as faCartPlusRegular,
    faChartBar as faChartBarRegular,
    faChartLine as faChartLineRegular,
    faChartNetwork as faChartNetworkRegular,
    faCheck as faCheckRegular,
    faCheckCircle as faCheckCircleRegular,
    faCheckSquare as faCheckSquareRegular,
    faChevronDoubleUp as faChevronDoubleUpRegular,
    faCircle as faCircleRegular,
    faClipboardCheck as faClipboardCheckRegular,
    faClock as faClockRegular,
    faClone as faCloneRegular,
    faCloud as faCloudRegular,
    faCloudDownload as faCloudDownloadRegular,
    faCloudUpload as faCloudUploadRegular,
    faCodeBranch as faCodeBranchRegular,
    faCog as faCogRegular,
    faComment as faCommentRegular,
    faComments,
    faCompass as faCompassRegular,
    faCompress as faCompressRegular,
    faContainerStorage as faContainerStorageRegular,
    faConveyorBelt as faConveyorBeltRegular,
    faCopy as faCopyRegular,
    faDollarSign as faDollarSignRegular,
    faDolly as faDollyRegular,
    faDollyEmpty,
    faDollyFlatbed as faDollyFlatbedRegular,
    faDoNotEnter as faDoNotEnterRegular,
    faDotCircle as faDotCircleRegular,
    faEdit as faEditRegular,
    faEllipsisH as faEllipsisHRegular,
    faEllipsisV as faEllipsisVRegular,
    faEngineWarning as faEngineWarningRegular,
    faEnvelope as faEnvelopeRegular,
    faEnvelopeOpen as faEnvelopeOpenRegular,
    faExchange as faExchangeRegular,
    faExclamationCircle as faExclamationCircleRegular,
    faExclamationTriangle as faExclamationTriangleRegular,
    faExpand as faExpandRegular,
    faExternalLink as faExternalLinkRegular,
    faEye as faEyeRegular,
    faEyeSlash as faEyeSlashRegular,
    faFile as faFileRegular,
    faFileAlt as faFileAltRegular,
    faFileArchive as faFileArchiveRegular,
    faFileContract as faFileContractRegular,
    faFileCsv as faFileCsvRegular,
    faFileDownload as faFileDownloadRegular,
    faFileExcel as faFileExcelRegular,
    faFileInvoice as faFileInvoiceRegular,
    faFileInvoiceDollar as faFileInvoiceDollarRegular,
    faFilePdf as faFilePdfRegular,
    faFileSignature as faFileSignatureRegular,
    faFileSpreadsheet as faFileSpreadsheetRegular,
    faFileUpload as faFileUploadRegular,
    faFileWord as faFileWordRegular,
    faFilter as faFilterRegular,
    faFlag as faFlagRegular,
    faFolderOpen as faFolderOpenRegular,
    faFolderTree,
    faForklift as faForkliftRegular,
    faFunnelDollar as faFunnelDollarRegular,
    faGlobe as faGlobeRegular,
    faGlobeEurope as faGlobeEuropeRegular,
    faGreaterThanEqual as faGreaterThanEqualRegular,
    faHandHoldingBox as faHandHoldingBoxRegular,
    faHandHoldingUsd as faHandHoldingUsdRegular,
    faHandReceiving as faHandReceivingRegular,
    faHandsUsd as faHandsUsdRegular,
    faHashtag as faHashtagRegular,
    faHistory as faHistoryRegular,
    faHome as faHomeRegular,
    faHouse as faHouseRegular,
    faIdBadge as faIdBadgeRegular,
    faIdCard as faIdCardRegular,
    faIdCardAlt as faIdCardAltRegular,
    faInbox as faInboxRegular,
    faInboxIn as faInboxInRegular,
    faInboxOut as faInboxOutRegular,
    faIndustryAlt as faIndustryAltRegular,
    faInfoCircle as faInfoCircleRegular,
    faIntersection as faIntersectionRegular,
    faInventory as faInventoryRegular,
    faKey as faKeyRegular,
    faLanguage as faLanguageRegular,
    faLayerGroup as faLayerGroupRegular,
    faLink as faLinkRegular,
    faList as faListRegular,
    faListAlt as faListAltRegular,
    faListUl as faListUlRegular,
    faLock as faLockRegular,
    faLongArrowRight as faLongArrowRightRegular,
    faMailbox as faMailboxRegular,
    faMap as faMapRegular,
    faMapMarked as faMapMarkedRegular,
    faMapPin as faMapPinRegular,
    faMedal as faMedalRegular,
    faMinus as faMinusRegular,
    faMoneyCheck as faMoneyCheckRegular,
    faPallet as faPalletRegular,
    faPalletAlt as faPalletAltRegular,
    faPaperclip as faPaperclipRegular,
    faPaperPlane as faPaperPlaneRegular,
    faPause,
    faPauseCircle as faPauseCircleRegular,
    faPen as faPenRegular,
    faPencil as faPencilRegular,
    faPlane as faPlaneRegular,
    faPlaneAlt as faPlaneAltRegular,
    faPlaneArrival as faPlaneArrivalRegular,
    faPlaneDeparture as faPlaneDepartureRegular,
    faPlay as faPlayRegular,
    faPlayCircle as faPlayCircleRegular,
    faPlug as faPlugRegular,
    faPlus as faPlusRegular,
    faPowerOff as faPowerOffRegular,
    faProjectDiagram as faProjectDiagramRegular,
    faQuestion as faQuestionRegular,
    faQuestionCircle as faQuestionCircleRegular,
    faQuoteLeft as faQuoteLeftRegular,
    faQuoteRight as faQuoteRightRegular,
    faRadar as faRadarRegular,
    faRedo as faRedoRegular,
    faRepeat as faRepeatRegular,
    faReply as faReplyRegular,
    faRetweet as faRetweetRegular,
    faRocket as faRocketRegular,
    faRocketLaunch as faRocketLaunchRegular,
    faRoute as faRouteRegular,
    faRss as faRssRegular,
    faRuler as faRulerRegular,
    faSatellite,
    faSatelliteDish as faSatelliteDishRegular,
    faSave as faSaveRegular,
    faScanner as faScannerRegular,
    faSearch as faSearchRegular,
    faSearchLocation,
    faShare as faShareRegular,
    faShieldAlt as faShieldAltRegular,
    faShip as faShipRegular,
    faShippingFast as faShippingFastRegular,
    faShippingTimed as faShippingTimedRegular,
    faShoppingCart as faShoppingCartRegular,
    faSignature,
    faSignIn as faSignInRegular,
    faSignOut as faSignOutRegular,
    faSlash as faSlashRegular,
    faSlidersVSquare as faSlidersVSquareRegular,
    faSortAmountDown as faSortAmountDownRegular,
    faSpinner as faSpinnerRegular,
    faStar as faStarRegular,
    faStarExclamation as faStarExclamationRegular,
    faStickyNote as faStickyNoteRegular,
    faStopwatch as faStopwatchRegular,
    faStream as faStreamRegular,
    faSun as faSunRegular,
    faSwimmer as faSwimmerRegular,
    faSync as faSyncRegular,
    faTable as faTableRegular,
    faTachometerAltFast as faTachometerAltFastRegular,
    faTasksAlt as faTasksAltRegular,
    faTerminal as faTerminalRegular,
    faTimes as faTimesRegular,
    faTimesCircle as faTimesCircleRegular,
    faTools as faToolsRegular,
    faTrain as faTrainRegular,
    faTrash as faTrashRegular,
    faTruck as faTruckRegular,
    faTruckLoading as faTruckLoadingRegular,
    faTruckMoving as faTruckMovingRegular,
    faTruckRamp as faTruckRampRegular,
    faUmbrellaBeach as faUmbrellaBeachRegular,
    faUndoAlt as faUndoAltRegular,
    faUnlink as faUnlinkRegular,
    faUsdCircle as faUsdCircleRegular,
    faUser as faUserRegular,
    faUserHeadset as faUserHeadsetRegular,
    faUserPlus as faUserPlusRegular,
    faUsers as faUsersRegular,
    faUserSecret as faUserSecretRegular,
    faWallet as faWalletRegular,
    faWarehouse as faWarehouseRegular
} from '@fortawesome/pro-regular-svg-icons';

import {
    faAnchor as faAnchorSolid,
    faAngleDown as faAngleDownSolid,
    faAngleUp as faAngleUpSolid,
    faAward as faAwardSolid,
    faBell as faBellSolid,
    faBoxes as faBoxesSolid,
    faBuilding as faBuildingSolid,
    faCalendar as faCalendarSolid,
    faChartLine as faChartLineSolid,
    faCheck as faCheckSolid,
    faClock as faClockSolid,
    faComments as faCommentsSolid,
    faContainerStorage as faContainerStorageSolid,
    faConveyorBelt as faConveyorBeltSolid,
    faDollarSign as faDollarSignSolid,
    faEllipsisV as faEllipsisVSolid,
    faEnvelopeOpen as faEnvelopeOpenSolid,
    faExclamation as faExclamationSolid,
    faFile as faFileSolid,
    faFileInvoiceDollar as faFileInvoiceDollarSolid,
    faForklift as faForkliftSolid,
    faHandHoldingUsd as faHandHoldingUsdSolid,
    faHandshakeAlt as faHandshakeAltSolid,
    faHistory as faHistorySolid,
    faIndustry as faIndustrySolid,
    faInfoCircle as faInfoCircleSolid,
    faList as faListSolid,
    faLongArrowRight as faLongArrowRightSolid,
    faMapPin as faMapPinSolid,
    faPalletAlt as faPalletAltSolid,
    faPlane as faPlaneSolid,
    faPlaneArrival as faPlaneArrivalSolid,
    faPlaneDeparture as faPlaneDepartureSolid,
    faPlusSquare as faPlusSquareSolid,
    faQuestion as faQuestionSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faRetweet as faRetweetSolid,
    faRoute as faRouteSolid,
    faRunning as faRunningSolid,
    faSave as faSaveSolid,
    faSearch as faSearchSolid,
    faShip as faShipSolid,
    faShoppingCart as faShoppingCartSolid,
    faSortAmountDown as faSortAmountDownSolid,
    faSortAmountUp as faSortAmountUpSolid,
    faSpinner as faSpinnerSolid,
    faStar as faStarSolid,
    faStopwatch as faStopwatchSolid,
    faTags as faTagsSolid,
    faTruck as faTruckSolid,
    faTruckLoading as faTruckLoadingSolid,
    faTruckMoving as faTruckMovingSolid,
    faUser as faUserSolid,
    faWarehouse as faWarehouseSolid,
    faWifiSlash,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faAward as faAwardDuo,
    faBooks as faBooksDuo,
    faBusAlt as faBusAltDuo,
    faCalendar as faCalendarDuo,
    faCalendarStar as faCalendarStarDuo,
    faChartBar as faChartBarDuo,
    faCheck as faCheckDuo,
    faCheckCircle as faCheckCircleDuo,
    faComments as faCommentsDuo,
    faCopy as faCopyDuo,
    faDoNotEnter as faDoNotEnterDuo,
    faExclamation as faExclamationDuo,
    faExclamationCircle as faExclamationCircleDuo,
    faExclamationTriangle as faExclamationTriangleDuo,
    faFile as faFileDuo,
    faFileChartPie as faFileChartPieDuo,
    faFileContract,
    faFileInvoiceDollar as faFileInvoiceDollarDuo,
    faHandHoldingBox,
    faHandHoldingUsd as faHandHoldingUsdDuo,
    faHandsUsd as faHandsUsdDuo,
    faInboxOut,
    faLifeRing as faLifeRingDuo,
    faListAlt as faListAltDuo,
    faMapMarkedAlt as faMapMarkedAltDuo,
    faPaperPlane as faPaperPlaneDuo,
    faPhone as faPhoneDuo,
    faPlane as faPlaneDuo,
    faRocket as faRocketDuo,
    faSearchLocation as faSearchLocationDuo,
    faShip as faShipDuo,
    faShoppingCart as faShoppingCartDuo,
    faSpinner as faSpinnerDuo,
    faSun as faSunDuo,
    faSync as faSyncDuo,
    faTable as faTableDuo,
    faTruck as faTruckDuo,
    faUserHeadset as faUserHeadsetDuo
} from '@fortawesome/pro-duotone-svg-icons';

import {
    faBoxAlt as faBoxAltL,
    faClock as faClockL,
    faHandsUsd as faHandsUsdL,
    faMinus as faMinusL,
    faPlus as faPlusL,
} from '@fortawesome/pro-light-svg-icons';

import {FaIconLibrary} from '@fortawesome/angular-fontawesome';

export default function loadIcons(library: FaIconLibrary) {
    // Standard
    library.addIcons(
        faArrowCircleRightRegular,
        faMoneyCheckRegular,
        faRepeatRegular,
        faArrowFromBottomRegular,
        faArrowToBottomRegular,
        faDoNotEnterRegular,
        faRadarRegular,
        faEngineWarningRegular,
        faAddressBookRegular,
        faAlarmClockRegular,
        faAlarmExclamationRegular,
        faAnalyticsRegular,
        faAnchorRegular,
        faAngleDownRegular,
        faAngleLeftRegular,
        faAngleRightRegular,
        faAngleUpRegular,
        faArchiveRegular,
        faArrowAltDownRegular,
        faArrowAltLeftRegular,
        faArrowAltUpRegular,
        faArrowFromLeftRegular,
        faArrowFromRightRegular,
        faArrowLeftRegular,
        faArrowRightRegular,
        faAsteriskRegular,
        faAwardRegular,
        faBanRegular,
        faBarcodeAltRegular,
        faBarsRegular,
        faBellRegular,
        faBookRegular,
        faBoxRegular,
        faBoxAltRegular,
        faBoxCheckRegular,
        faBoxesRegular,
        faGlobeRegular,
        faBriefcaseRegular,
        faBroomRegular,
        faBugRegular,
        faBuildingRegular,
        faCalendarRegular,
        faCalendarAltRegular,
        faCalendarEditRegular,
        faCameraRegular,
        faCartPlusRegular,
        faChartBarRegular,
        faChartLineRegular,
        faChartNetworkRegular,
        faCheckRegular,
        faCheckCircleRegular,
        faCheckSquareRegular,
        faChevronDoubleUpRegular,
        faCircleRegular,
        faClipboardCheckRegular,
        faClockRegular,
        faCloneRegular,
        faCloudRegular,
        faCloudDownloadRegular,
        faCloudUploadRegular,
        faCodeBranchRegular,
        faCogRegular,
        faCommentRegular,
        faCompassRegular,
        faCompressRegular,
        faContainerStorageRegular,
        faConveyorBeltRegular,
        faCopyRegular,
        faDollarSignRegular,
        faDollyRegular,
        faDollyFlatbedRegular,
        faDotCircleRegular,
        faEditRegular,
        faEllipsisHRegular,
        faEllipsisVRegular,
        faEnvelopeRegular,
        faEnvelopeOpenRegular,
        faExchangeRegular,
        faExclamationCircleRegular,
        faExclamationTriangleRegular,
        faExpandRegular,
        faExternalLinkRegular,
        faEyeRegular,
        faEyeSlashRegular,
        faFileRegular,
        faFileAltRegular,
        faFileArchiveRegular,
        faFileContractRegular,
        faFileCsvRegular,
        faFileDownloadRegular,
        faFileExcelRegular,
        faFileInvoiceRegular,
        faFileInvoiceDollarRegular,
        faFilePdfRegular,
        faFileSignatureRegular,
        faFileSpreadsheetRegular,
        faFileUploadRegular,
        faFileWordRegular,
        faFilterRegular,
        faFlagRegular,
        faFolderOpenRegular,
        faForkliftRegular,
        faFunnelDollarRegular,
        faGlobeEuropeRegular,
        faGreaterThanEqualRegular,
        faHandHoldingBoxRegular,
        faHandHoldingUsdRegular,
        faHandReceivingRegular,
        faHandsUsdRegular,
        faHashtagRegular,
        faHistoryRegular,
        faHomeRegular,
        faHouseRegular,
        faIdCardRegular,
        faIdCardAltRegular,
        faInboxRegular,
        faInboxInRegular,
        faInboxOutRegular,
        faIndustryAltRegular,
        faInfoCircleRegular,
        faIntersectionRegular,
        faInventoryRegular,
        faKeyRegular,
        faLanguageRegular,
        faLayerGroupRegular,
        faLinkRegular,
        faListRegular,
        faListAltRegular,
        faListUlRegular,
        faLockRegular,
        faLongArrowRightRegular,
        faMailboxRegular,
        faMapRegular,
        faMapMarkedRegular,
        faMapPinRegular,
        faMedalRegular,
        faMinusRegular,
        faPalletRegular,
        faPalletAltRegular,
        faPaperPlaneRegular,
        faPaperclipRegular,
        faPauseCircleRegular,
        faPenRegular,
        faPencilRegular,
        faPlaneRegular,
        faPlaneAltRegular,
        faPlaneArrivalRegular,
        faPlaneDepartureRegular,
        faPlayRegular,
        faComments,
        faPlayCircleRegular,
        faPlugRegular,
        faPlusRegular,
        faPowerOffRegular,
        faProjectDiagramRegular,
        faQuestionRegular,
        faQuestionCircleRegular,
        faRedoRegular,
        faReplyRegular,
        faRetweetRegular,
        faRocketRegular,
        faRocketLaunchRegular,
        faRouteRegular,
        faRssRegular,
        faRulerRegular,
        faSaveRegular,
        faScannerRegular,
        faSearchRegular,
        faShareRegular,
        faShieldAltRegular,
        faShipRegular,
        faShippingFastRegular,
        faShippingTimedRegular,
        faShoppingCartRegular,
        faSignInRegular,
        faSignOutRegular,
        faSlashRegular,
        faSlidersVSquareRegular,
        faSortAmountDownRegular,
        faSpinnerRegular,
        faStarRegular,
        faStarExclamationRegular,
        faStickyNoteRegular,
        faStopwatchRegular,
        faStreamRegular,
        faSunRegular,
        faSwimmerRegular,
        faSyncRegular,
        faTableRegular,
        faTachometerAltFastRegular,
        faTasksAltRegular,
        faTimesRegular,
        faTimesCircleRegular,
        faToolsRegular,
        faTrainRegular,
        faTrashRegular,
        faTruckRegular,
        faTruckLoadingRegular,
        faTruckMovingRegular,
        faTruckRampRegular,
        faUmbrellaBeachRegular,
        faUndoAltRegular,
        faUnlinkRegular,
        faUsdCircleRegular,
        faUserRegular,
        faUserHeadsetRegular,
        faUserPlusRegular,
        faUserSecretRegular,
        faUsersRegular,
        faWarehouseRegular,
        faTerminalRegular,
        faQuoteRightRegular,
        faQuoteLeftRegular,
        faWalletRegular,
        faSatelliteDishRegular,
        faSatellite,
        faIdBadgeRegular,
        faDollyEmpty,
        faPause,
        faSignature,
        faArrowToLeft,
        faFolderTree,
        faSearchLocation
    );

    // Solid
    library.addIcons(
        faBellSolid,
        faCommentsSolid,
        faFileSolid,
        faStarSolid,
        faSortAmountDownSolid,
        faHistorySolid,
        faClockSolid,
        faSaveSolid,
        faShoppingCartSolid,
        faAngleDownSolid,
        faDollarSignSolid,
        faStopwatchSolid,
        faAngleUpSolid,
        faShipSolid,
        faPlusSquareSolid,
        faQuestionCircleSolid,
        faConveyorBeltSolid,
        faIndustrySolid,
        faAwardSolid,
        faChartLineSolid,
        faForkliftSolid,
        faTruckLoadingSolid,
        faFileInvoiceDollarSolid,
        faRetweetSolid,
        faHandshakeAltSolid,
        faRouteSolid,
        faLongArrowRightSolid,
        faCalendarSolid,
        faListSolid,
        faInfoCircleSolid,
        faRunningSolid,
        faPlaneSolid,
        faSortAmountUpSolid,
        faExclamationSolid,
        faSearchSolid,
        faEllipsisVSolid,
        faEnvelopeOpenSolid,
        faWarehouseSolid,
        faTagsSolid,
        faBuildingSolid,
        faBoxesSolid,
        faHandHoldingUsdSolid,
        faQuestionSolid,
        faPlaneDepartureSolid,
        faPlaneArrivalSolid,
        faAnchorSolid,
        faMapPinSolid,
        faContainerStorageSolid,
        faPalletAltSolid,
        faTruckSolid,
        faUserSolid,
        faCheckSolid,
        faTruckMovingSolid,
        faWifiSlash
    );

    // Duotone
    library.addIcons(
        faSpinnerDuo,
        faCheckCircleDuo,
        faExclamationCircleDuo,
        faExclamationTriangleDuo,
        faHandsUsdDuo,
        faCalendarStarDuo,
        faFileDuo,
        faFileInvoiceDollarDuo,
        faCopyDuo,
        faListAltDuo,
        faTableDuo,
        faChartBarDuo,
        faDoNotEnterDuo,
        faSearchLocationDuo,
        faSyncDuo,
        faShipDuo,
        faPaperPlaneDuo,
        faShoppingCartDuo,
        faMapMarkedAltDuo,
        faBooksDuo,
        faPhoneDuo,
        faLifeRingDuo,
        faUserHeadsetDuo,
        faAwardDuo,
        faBusAltDuo,
        faRocketDuo,
        faCalendarDuo,
        faHandHoldingUsdDuo,
        faCheckDuo,
        faExclamationDuo,
        faSunDuo,
        faTruckDuo,
        faPlaneDuo,
        faFileChartPieDuo,
        faCommentsDuo,
        faHandHoldingBox,
        faSpinnerSolid,
        faInboxOut,
        faFileContract
    );

    // Light
    library.addIcons(faBoxAltL, faHandsUsdL, faClockL, faMinusL, faPlusL);

    // Brands
}
