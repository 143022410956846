import { Component } from '@angular/core';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

export interface RequestedNavigation {
    component: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
}

@Component({
    selector: 'qwyk-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent {
    design$ = this.siteConfig.design$;
    authenticationType$ = this.siteConfig.authenticationType$;
    isMagayaNetwork$ = this.siteConfig.config$.pipe(
        map(config => (config ? config.is_magaya_network : false))
    );
    component = 'login';
    navigationData = null;
    allowContinueWithoutLogin = false;

    constructor(
        private siteConfig: SiteConfigFacade,
        private ngbModal: NgbActiveModal
    ) {}

    closeDialog() {
        this.ngbModal.close();
    }

    cancelDialog(reason?) {
        this.ngbModal.dismiss(reason);
    }

    onNavigationRequested(navigation: RequestedNavigation) {
        this.navigationData = navigation.data;
        this.component = navigation.component;
    }
}
