export interface ShipmentSummary {
    id: string;
}

export interface Shipment {
    id: string;
    has_internal_locode?: boolean;
    is_warning_origin?: boolean;
    is_warning_destination?: boolean;
}

export interface ShipmentTrackingEvent {
    id: number;
    timestamp: string | Date;
    timezone: string;
    timezone_code: string;
    timestamp_local: string | Date;
    code: string;
    description: string;
    location: any | null;
    container: any | null;
    is_internal: boolean;
}

export interface ShipmentDataContainer {
    id: number;
    container_number: string;
    iso6346_code: string;
    seal_number: string;
    availability_known: boolean;
    available_for_pickup: boolean;
    location_at_pod_terminal: string;
    pickup_lfd?: string | Date;
    pickup_lfd_local?: string | Date;
    pickup_appointment_at?: string | Date;
    pickup_appointment_at_local?: string | Date;
    timezone?: string;
    timezone_code?: string;
    has_fees?: boolean;
    has_holds?: boolean;
    is_tracked?: boolean;
}


export enum GeoTrackTransportMode {
    ROAD = 'ROAD',
    RAIL = 'RAIL',
    BARGE = 'BARGE',
    OCEAN = 'OCEAN',
    AIR = 'AIR',
    UNKNOWN = 'UNKNOWN'
}

export enum GeoTrackSource {
    AIS = 'AIS',
    CARRIER = 'CARRIER',
    CARRIER_EXTENDED = 'CARRIER_EXTENDED',
    UNKNOWN = 'UNKNOWN',
}

export interface Position {
    lat: number;
    lng: number;
}

export interface GeoTrackTransport {
    mode: GeoTrackTransportMode;
    name: string | null;
    code: string | null;
    speed: number | null;
    course: number | null;
    heading: number | null;
}

export interface GeoTrackElement {
    timestamp: Date;
    name: string;
    source: GeoTrackSource;
    position: Position;
    transport: GeoTrackTransport;
}
