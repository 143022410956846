import { ShipmentsService } from './../../services/shipments.service';
import * as shipmentActions from './../actions/shipments.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ShipmentsEffects {
    constructor(
        private actions$: Actions,
        private service: ShipmentsService,
        private store: Store<AppState>
    ) {}

    loadShipment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(shipmentActions.loadShipment),
            switchMap(action =>
                this.service.getShipment(action.shipmentId).pipe(
                    map(shipment =>
                        shipmentActions.loadShipmentSuccess({ shipment })
                    ),
                    catchError(error =>
                        of(shipmentActions.loadShipmentFailure({ error }))
                    )
                )
            )
        )
    );

    loadShipmentActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(shipmentActions.loadShipmentActivity),
            switchMap(action =>
                this.service
                    .getShipmentActivity(action.shipmentId, action.limit)
                    .pipe(
                        map(activity =>
                            shipmentActions.loadShipmentActivitySuccess({
                                activity,
                            })
                        ),
                        catchError(() =>
                            of(shipmentActions.loadShipmentActivityFailure())
                        )
                    )
            )
        )
    );

    loadMoreShipmentActivity = createEffect(() =>
        this.actions$.pipe(
            ofType(shipmentActions.loadMoreShipmentActivity),
            switchMap(action =>
                this.service
                    .getShipmentActivity(
                        action.shipmentId,
                        action.limit,
                        action.offset
                    )
                    .pipe(
                        map(activity =>
                            shipmentActions.loadMoreShipmentActivitySuccess({
                                activity,
                            })
                        ),
                        catchError(() =>
                            of(
                                shipmentActions.loadMoreShipmentActivityFailure()
                            )
                        )
                    )
            )
        )
    );

    createCommentShipmentActivity = createEffect(() =>
        this.actions$.pipe(
            ofType(shipmentActions.postComment),
            switchMap(action =>
                this.service
                    .postShipmentComment(action.shipmentId, {
                        data: action.data,
                    })
                    .pipe(
                        map(activity =>
                            shipmentActions.postCommentSuccess({ activity })
                        ),
                        catchError(() =>
                            of(shipmentActions.postCommentFailure())
                        )
                    )
            )
        )
    );

    deleteComment = createEffect(() =>
        this.actions$.pipe(
            ofType(shipmentActions.deleteComment),
            switchMap(action =>
                this.service
                    .deleteShipmentComment(
                        action.shipmentId,
                        action.activity.id
                    )
                    .pipe(
                        map(() =>
                            shipmentActions.deleteCommentSuccess({
                                activity: action.activity,
                            })
                        ),
                        catchError(() =>
                            of(shipmentActions.deleteCommentFailure())
                        )
                    )
            )
        )
    );
}
